import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { ModalService } from './media-preview-service';

@Component({
  selector: 'app-media-preview-modal',
  templateUrl: './media-preview-modal.component.html',
  styleUrl: './media-preview-modal.component.css',
})

export class MediaPreviewModalComponent implements AfterViewInit {
  @Input() data: any;
  @Input() url: string | null = null;
  @Input() urlType: string | null = null;
  isVisible = false;
  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;
  scrollPosition = 0;
  windowScrollPosition = 0;

  constructor(private modalService: ModalService) {
  }

  ngAfterViewInit(): void {
    if (this.videoPlayer) {
      this.videoPlayer.nativeElement.addEventListener('fullscreenchange', this.onFullscreenChange.bind(this));
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.windowScrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if(this.windowScrollPosition !== 0){
      this.scrollPosition = this.windowScrollPosition;
    }
  }

  @HostListener('document:fullscreenchange')
  onFullscreenChange() {
    if (document.fullscreenElement) {
      this.disableScroll();
    } else {
      this.enableScroll();
      this.preventScrollJump();
    }
  }

  private disableScroll() {
    document.body.style.overflow = 'hidden';
  }

  private enableScroll() {
    document.body.style.overflow = 'auto';
  }

  private preventScrollJump() {
    if (this.scrollPosition !== null) {
      setTimeout(() => {
        window.scrollTo(0, this.scrollPosition);
      }, 0);
    }
  }

  ngOnInit(): void {
    this.modalService.isVisible$.subscribe((isVisible) => {
      this.isVisible = isVisible;
      if(this.isVisible){
        this.disableScroll();
      }
    });

    this.modalService.modalParams$.subscribe((params) => {
      if (params) {
        this.url = params.url;
        this.urlType = params.urlType;
      }
    });
  }

  close() {
    this.modalService.hide();
    this.enableScroll();
  }
}
