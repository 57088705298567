import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private isVisibleSubject = new BehaviorSubject<boolean>(false);
  private modalParamsSubject = new BehaviorSubject<{ url: string | null, urlType: string | null } | null>(null);

  isVisible$ = this.isVisibleSubject.asObservable();
  modalParams$ = this.modalParamsSubject.asObservable();

  show(url: string, urlType: string) {
    this.modalParamsSubject.next({ url, urlType });
    this.isVisibleSubject.next(true);
  }

  hide() {
    this.modalParamsSubject.next(null);
    this.isVisibleSubject.next(false);
  }

  preventScroll(event: any) {
    event.preventDefault();
  }
}
