<div class="modal-overlay modal" *ngIf="isVisible" >
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-modal-fullscreen">
  <div class="modal-content" appClickOutside (appClickOutside)="close()">
    <div class="modal-header justify-content-between p-3">
      <h4 class="modal-title mb-0">
        Preview
      </h4>
      <a
      class="fs-xl text-danger ctmptr text-decoration-none"
        aria-label="Close"
        (click)="close()"
      ><strong><i class="ri-close-large-fill"></i></strong></a>
    </div>
    <div class="modal-body" >
    <div *ngIf="url && urlType === 'video'">
      <video #videoPlayer *ngIf="urlType === 'video'" width="100%" height="100%" [autoplay]="false" [loop]="false" [muted]="false" [disablePictureInPicture]="true" controls>
        <source [src]="url" type="video/mp4">
        <source [src]="url" type="video/webm" />
        <source [src]="url" type="video/ogg" />
      </video>
    </div>
    <div *ngIf="url && urlType === 'image'">
      <img [src]="url" alt="Image" width="100%" height="auto">
    </div>
  </div>
    <div class="modal-footer p-3">
      <button type="button" class="btn btn-carolina-blue" (click)="close()">
        Close
      </button>
    </div>
  </div>
</div>
</div>
